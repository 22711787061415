<template>
    <section class="list-popular-services-container">
        <div class="list-popular-services">
            <WatsonAtomDynamicText
                v-if="title"
                class="list-popular-services__title"
                :text="title"
                :typography="typography.title"
            />
            <ul
                class="list-popular-services__list"
            >
                <li
                    v-for="item in items"
                    :key="item.id"
                    class="list-popular-services__list-item"
                >
                    <WatsonThemeImageCard
                        :card="item"
                        :typography="typography"
                    />
                </li>
            </ul>
        </div>
    </section>
</template>

<script setup>
import WatsonAtomDynamicText from '@atoms/dynamic-text/watson-atom-dynamic-text.vue';
import WatsonThemeImageCard from '../image-card/watson-theme-image-card.vue';

const props = defineProps({
    content: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    items: {
        type: Array,
        required: true,
    },
    title: {
        type: String,
        required: false,
        default: null,
    },
    background: {
        type: String,
        required: false,
        default: null,
    },
    titleColor: {
        type: String,
        required: false,
        default: null,
    },
    cardStyle: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    typography: {
        type: Object,
        required: false,
        default: () => ({
            title: {
                visual: 'heading-2-bold',
                tag: 'h2',
            },
            cardTitle: {
                visual: 'heading-4-bold',
                tag: 'h3',
            },
        }),
    },
});
</script>

<style lang="scss">
@use 'watson-theme-list-popular-services';
</style>

<style lang="scss" scoped>
@layer theme {
    .list-popular-services-container {
        --background: v-bind('props.background');
        --title-color: v-bind('props.titleColor');
    }
}
</style>
